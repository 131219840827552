.containerVA {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-selects{
    display: flex;
    justify-content: space-around;
}

.containerVA img {
    margin: 2rem 2rem;
    height: auto;
    width: 100px;
}

.containerVA h3 {
    margin-bottom: 1rem;
    color: #2E915C;
}

.containerVA h4 {
    margin-bottom: 2rem;
    color: #194B74;
}

.containerVA button {
    margin-top: 7rem;
}

.buttonA {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -4rem;
}

.productop {
    display: flex;
    flex-direction: column;
}

.productop label {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #194B74;
    font-weight: bold;
}