html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: "Raleway";
    height: 100%;
    overflow-x: hidden;
}

.navigation-bar a:hover {
    cursor: pointer;
    color: #2d73ac;
    transform: translateY(-2px);
    transition: 0.3s ease-in;
    text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
}

.card-option :hover {
    cursor: pointer;
    color: #2d73ac;
    transform: translateY(-20px);
    transition: 0.3s;

}

button:hover {
    transform: translateY(-1px);
    transition: 0.3s ease-in;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}

button {
    transition: 0.3s ease-in;
}

#root {
    height: 100%;
}

.appContainer {
    height: 100%;
}


/* btns */

button,
.btn {
    border-radius: 4px;
    border: none;
    padding: 12px;
    margin: 30px 0px;
    transition: 0.5s ease;
    font-size: 0.8vw;
    font-weight: 600;
}

.blue {
    background: #194b74;
    color: white;
}

.yellow {
    background: #fbc02a;
    color: #194b74;
}

.green {
    background: #30881a;
    color: white;
}

.gray {
    background: #727272;
    color: white;
}


/* btns hover */

.yellow:hover {
    background-color: #f0ac00;
    cursor: pointer;
    transition: 0.5s ease;
}

.blue:hover {
    background: #567384;
    transition: 0.5s ease;
    cursor: pointer;
}

.green:hover {
    background-color: #0cb829;
    cursor: pointer;
    transition: 0.5s ease;
}

label {
    color: #567384;
}


/* input error */

.input-error .custom-file-upload {
    border: 1px solid red;
}

.input-error>input {
    border: 1px solid red;
}

.input-error span {
    color: red;
}


/* navabar */

.navigation-bar {
    z-index: 1;
    /* position: absolute; */
    background: white;
    width: 100%;
    /* i'm assuming full width */
    height: auto;
    /* change it to desired width */
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
    z-index: 1;
}

.navigation-bar>div {
    display: flex;
}

.logo {
    display: inline-block;
    vertical-align: top;
    height: 50px;
}

.left-cont a {
    margin: 5px 0px 5px 10px;
}

.navigation-bar a {
    display: inline-block;
    transition: 0.3s ease-in;
    vertical-align: top;
    margin-right: 20px;
    line-height: 40px;
    /* if you want it vertically middle of the navbar */
    text-decoration: none;
    font-weight: 700;
    color: #194b74;
    font-size: 0.8vw;
}

.separator {
    border-left: 2px solid #567384;
    padding-left: 10px;
}

.active {
    border-bottom: 2px solid #fbc02a;
}


/* landing section */

.landing-section__container {
    display: flex;
    position: relative;
}

.landing-section__container div {
    color: #194b74;
}

.landing-section__container h3 {
    font-style: normal;
    font-size: 1.5vw;
    font-weight: 800;
    margin: 0;
    margin: 20px 0px;
}

.landing-section__container h2 span {
    font-size: 2.5vw;
    font-family: "Raleway", sans-serif;
}

h2 {
    font-weight: 800;
    font-size: 2vw;
}

.landing-section__container ul {
    list-style: none;
    margin-bottom: 50px;
}

.landing-section__container ul li::before {
    content: "\2022";
    color: #ffd466;
    font-size: 40px;
    font-weight: bold;
    display: inline-block;
    width: 0.5em;
    margin-left: -1em;
}

.landing-section__container li {
    display: flex;
    align-items: center;
    line-height: 40px;
    font-weight: 700;
}

.landing-section__container li img {
    height: 3 vw;
}

.landing-section__second-column {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.landing-section__first-column input {
    margin-right: 40px;
}

.landing-section__first-column {
    padding: 50px 0px 0px 80px;
    width: 50%;
}

.landing-section__first-column-carousel {
    padding: 50px 0px 0px 80px;
    width: 100%;
}
.landing-section__first-column-carousel_new {
    width: 100%;
}

input,
.input {
    width: 15vw;
    height: 2.5vh;
    background: #f6f6f6;
    border-radius: 2px;
    border: 0px;
    padding: 12px;
}

.landing-section__second-column-texto {
    color: white !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #194b74;
    padding: 30px;
}

.landing-section__second-column-texto h2 {
    margin: 20px 0px;
}


/* input[type="range"] {
  width: 60%;
}
input[type="range"]::-webkit-slider-runnable-track {
  height: 10px;
  cursor: pointer;
  background: #ffd466;
  border-radius: 5px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ffd466;
}
input[type="range"]::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  margin-top: -3px;
  cursor: ew-resize;
  background-color: rgb(255, 120, 0) !important;
} */

input[type="range"] {
    width: 60%;
    margin: 13.8px 0;
    background-color: transparent;
    -webkit-appearance: none;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    background: #ffd466;
    border: 0.2px solid #ffd466;
    border-radius: 5.5px;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
    margin-top: -14px;
    width: 36px;
    height: 36px;
    background: #fbc02a;
    border: 1px solid #fbc02a;
    border-radius: 50px;
    cursor: pointer;
    -webkit-appearance: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ffd770;
}

input[type="range"]::-moz-range-track {
    background: #ffd466;
    border: 0.2px solid #ffd466;
    border-radius: 5.5px;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
    width: 36px;
    height: 36px;
    background: #fbc02a;
    border: 1px solid #fbc02a;
    border-radius: 50px;
    cursor: pointer;
}

input[type="range"]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 14.8px 0;
    color: transparent;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
}

input[type="range"]::-ms-fill-lower {
    background: #ffd15c;
    border: 0.2px solid #ffd466;
    border-radius: 11px;
}

input[type="range"]::-ms-fill-upper {
    background: #ffd466;
    border: 0.2px solid #ffd466;
    border-radius: 11px;
}

input[type="range"]::-ms-thumb {
    width: 36px;
    height: 36px;
    background: #fbc02a;
    border: 1px solid #fbc02a;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
}

input[type="range"]:focus::-ms-fill-lower {
    background: #ffd466;
}

input[type="range"]:focus::-ms-fill-upper {
    background: #ffd770;
}


/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/

@supports (-ms-ime-align: auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type="range"] {
        margin: 0;
        /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
}

.range-text {
    color: white;
}

.dashboard__card .range-text span {
    color: #194b74 !important;
}

.range-text span {
    color: white;
    font-weight: 900;
    font-size: 20px;
}

.range-text {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.form-body {
    display: flex;
    flex-wrap: wrap;
}

.form-body label {
    font-size: 1vw;
    margin: 10px 0px;
}

.form-body div {
    display: flex;
    flex-direction: column;
    flex: 0 50%;
}

.familia1 {
    width: 100%;
}

.background-triangle {
    z-index: -1;
    position: absolute;
    height: 200px;
}


/* como-funciona section */

.como-funciona {
    position: relative;
}

.como-funciona>img {
    position: absolute;
    z-index: 1;
}

.como-funciona__container {
    color: white;
    background-color: #194b74;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.como-funciona__info {
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.como-funciona__info div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 10px;
}

.como-funciona__info div h4 {
    margin: 20px 0px;
    width: 150px;
    font-size: 1vw;
    height: 50px;
    text-align: center;
}

.como-funciona__info div span {
    text-align: center;
    width: 10vw;
}

.como-funciona__container img {
    width: 80px;
}

.como-funciona__container button {
    margin: 30px 0px;
    /* background: #fbc02a; */
    border-radius: 4px;
    border: 0px;
    padding: 14px;
    color: #194b74;
    font-weight: 700;
}

.item-text {
    width: 70%;
}


/* banner section */

.banner-section__container {
    height: 250px;
    display: flex;
}

.banner-section__container div:first-child {
    background-color: #fbc02a !important;
}

.como-funciona__container {
    padding: 30px !important;
}

.banner-section__container div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #194b74;
    font-weight: 900;
    padding: 0px 70px;
    font-size: 28px;
    background-color: #ffd466;
    flex: 1;
}

.banner-section__container div h3 {
    text-align: center;
    font-size: 2vw;
}

.como-funciona__info div h4 {
    margin: 20px 0px;
    width: 150px;
    font-size: 14px;
    height: 50px;
}

.como-funciona__info div span {
    width: 100%;
}

.como-funciona__info div {
    width: 13%;
}

.banner-section__container img {
    max-width: 100%;
    max-height: 100%;
}


/* tienes dudas */

.tienes-dudas-section__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.tienes-dudas-section__container h2 {
    display: flex;
    color: #194b74;
}

.tienes-dudas-section__container>span {
    font-size: 1vw;
    margin: 20px 0px;
    color: #194b74;
}

.tienes-dudas-section__container>span a {
    text-decoration: none;
    color: #194b74;
}

.tienes-dudas-section__button-group {
    margin: 70px 0px;
}

.tienes-dudas-section__button-group a {
    margin: 0px 10px;
    text-decoration: none;
    font-weight: 600;
}


/* reviews */

.reviews__container {
    padding: 50px 0px;
    background-color: #194b74;
    display: flex;
    flex-direction: column;
}

.reviews__container h2 {
    text-align: center;
    color: white;
}

.reviews__cards-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.reviews__cards-container a {
    color: white;
    font-size: 28px;
}

.card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 80%;
    height: 400px;
}

.card img {
    width: 100px;
    border-radius: 50%;
}


/* footer */

footer {
    background-color: white;
    padding: 50px;
}

.footer-no-home {
    /* position: absolute;
  bottom: 0; */
}

.footer__first-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer__first-container div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer__first-container a {
    color: #194b74;
    font-weight: 600;
    text-decoration: none;
    margin: 0px 10px;
}

.footer__first-container .logo {
    margin-right: 100px;
}


/*
** Login
*/

.login__content {
    background-color: #194b74;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login__blue-vector {
    position: absolute;
    width: 43vh;
    bottom: 50;
    left: 0;
}

.login__family {
    position: absolute;
    width: 60vh;
    bottom: 0;
    right: 23px;
    z-index: 1;
}

.login__content h1 {
    margin: 2vh;
    color: #194b74;
    font-weight: 800;
}

.login__content h3 {
    margin: 0;
    color: #194b74;
    font-weight: 600;
    text-align: center;
}

.login__card {
    border-radius: 8px;
    padding: 7vh 0vh 15px 0px;
    background: white;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login__input-group {
    display: flex;
    flex-direction: column;
    width: 35%;
    margin: 5vh 0px;
}

.login__input-group input {
    width: 100%;
    padding: 12px 7px !important;
}

.login__input-group label {
    margin: 1vh 0px;
    font-size: 0.8vw;
}

.login__input-group a {
    text-align: center;
    color: #2d73ac;
    font-size: 0.9vw;
    text-decoration: none;
    margin: 1vh 0px;
}

.password-input {
    position: relative;
}

.password-input img {
    position: absolute;
    bottom: 1.3vh;
    right: 0.5px;
    width: 24px;
    height: 24px;
}


/**
** Solicitud
*/

.solicitud__container {
    color: #194b74;
    height: 100%;
    display: flex;
    justify-content: center;
}

.solicitud__container h1 {
    font-weight: 800;
}

.solicitud-1__tabs a {
    text-decoration: none;
    margin: 2vh 0px;
    font-size: 1.3vw;
    font-weight: 700;
    color: #194b74;
    opacity: 0.3;
}

.solicitud__container a.active {
    color: #194b74;
    opacity: 1 !important;
}

.solicitud-1__form-solicitud {
    margin: 15vh;
    display: flex;
    width: 40%;
    flex-direction: column;
    align-items: center;
}

.solicitud-1__form-solicitud button {}

.solicitud-1__tabs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.solicitud-1__input-group {
    margin-top: 3vh;
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
}

.solicitud-1__input-group .password_input {
    margin: 0;
}

.solicitud-1__input-group div {
    flex: 0 45%;
    display: flex;
    flex-direction: column;
    margin: 1vh 0px;
}

.solicitud-1__input-group div span {
    text-align: end;
    font-size: 0.8vw;
}

.solicitud-1__form-solicitud p {
    text-align: center;
}

.solicitud-2__content {
    text-align: center;
    margin: 15vh;
    display: flex;
    width: 40%;
    flex-direction: column;
    align-items: center;
}

.solicitud-2__content img {
    margin-top: 10vh;
}

.solicitud-3__content {
    text-align: center;
    margin: 15vh;
    display: flex;
    width: 40%;
    flex-direction: column;
    align-items: center;
}

.solicitud-3__content img {
    margin-top: 5vh;
    width: 20vh;
}

.solicitud-4__tabs a {
    text-decoration: none;
    margin: 2vh 0px;
    font-size: 1.3vw;
    font-weight: 700;
    color: #194b74;
    display: flex;
}

.solicitud-4__form-solicitud {
    margin: 15vh;
    display: flex;
    width: 55%;
    flex-direction: column;
    align-items: center;
}

.solicitud-4__form-solicitud button {
    margin: 2vh 0px !important;
}

.solicitud-4__tabs {
    display: flex;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.solicitud-4__tabs a span {
    background: #2e915c;
    border-radius: 20px;
    opacity: 1;
    font-size: 1vw;
    padding: 0.5vh 1vw;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 1vw;
}

.solicitud-4__input-group {
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
}

.solicitud-4__input-group .password_input {
    margin: 0;
}

.solicitud-4__input-group div {
    flex: 0 10%;
    display: flex;
    flex-direction: column;
    margin: 1vh 0px;
}

.solicitud-4__input-group label img {
    margin-left: 0.5vw;
}

.solicitud-4__input-group div span {
    text-align: end;
    font-size: 0.8vw;
}

.solicitud-4__form-solicitud p {
    text-align: center;
}

.custom-file-input::before {
    content: "Select some files";
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    height: 2.5vh;
    background: #f6f6f6;
    border-radius: 2px;
    border: 0px;
    padding: 12px;
    flex: 0 10%;
    display: flex;
    width: 15vw;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9vw;
}

.input-select {
    background: #f6f6f6;
    border-radius: 2px;
    padding: 12px;
    flex: 0 1 10%;
    display: flex;
    width: 16vw;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9vw;
}

.justify-start {
    justify-content: flex-start;
}

.justify-start div:first-child {
    margin-right: 2.2vw;
}

.solicitud-4__form-solicitud button {
    margin-top: 5vh !important;
}

progress[value] {
    width: 60%;
    padding: 1vh 0px;
}

.solicitud-5__form-solicitud {
    margin: 15vh;
    display: flex;
    width: 55%;
    flex-direction: column;
    align-items: center;
}

.solicitud-5__form-solicitud span {
    text-align: center;
    font-weight: 700;
    margin: 1vh 0px;
}

.solicitud-5__form-solicitud h1 {
    margin: 3vh 0px 0px 0px;
}

.solicitud-5__tabs {
    display: flex;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.solicitud-5__tabs a span {
    background: #2e915c;
    border-radius: 20px;
    opacity: 1;
    font-size: 1vw;
    padding: 0.5vh 1vw;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 1vw;
}

.solicitud-5__tabs a {
    text-decoration: none;
    margin: 2vh 0px;
    font-size: 1.3vw;
    font-weight: 700;
    color: #194b74;
    display: flex;
}

.clock {
    margin: 7vh 0px 0px 0px;
    width: 20vh;
}

text {
    text-align: center;
    width: 70%;
    margin: 5vh 0px;
}

.solicitud-6__form-solicitud {
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: center;
}

.solicitud-6__form-solicitud span {
    text-align: justify;
    line-height: 3vh;
    font-weight: 500;
    margin: 1vh 0px;
}

.solicitud-6__form-solicitud a {
    padding: 12px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.solicitud-6__form-solicitud a img {
    margin-right: 0.5vw;
}

.solicitud-6__form-solicitud h1 {
    margin: 3vh 0px 0px 0px;
}

.solicitud-6__logo {
    width: 10vw;
    margin: 3vh 0px;
}

.solicitud-6__checkmark {
    width: 7vw;
    margin: 2vh 0px;
}

.solicitud-6__disclosure {
    color: #acacac;
    margin-top: 5vh !important;
}


/* dashboard */

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0px 5px;
    z-index: 1;
}

.dropdown-content a {
    float: none;
    color: #194b74;
    padding: 12px 16px;
    background-color: #f9f9f9;
    text-decoration: none;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.dropdown-content a:last-child {
    border-top: 1px solid black;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dashboard__container {
    padding: 10px 2px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #194b74;
}

.dashboard__container h1 {
    font-size: 1.5vw;
    margin: 2vh 0px 0px 0px;
    font-weight: 800;
}

.dashboard__header {
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
    top: 5vh;
    left: 1vw;
}

.dashboard__vector {
    position: absolute;
    width: 15vw;
    align-self: baseline;
}

.dashboard__card {
    background-image: url("../assets/dark-blue-vector.png");
    background-size: 25vh;
    background-repeat: no-repeat;
    padding: 2vh 0vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 40%;
}

.dashboard__card h2 {
    text-align: center;
    font-size: 1.5vw;
    width: 90%;
}

.dashboard__range-text {
    width: 70%;
    display: flex;
    font-weight: 700;
    font-size: 1.2vw;
    justify-content: space-between;
    margin-bottom: 2vh;
}

.dashboard__card span {
    margin-bottom: 1vh;
}

.dashboard__header-banner-en-proceso {
    background: #d1d1d1;
    border-radius: 8px;
    color: #194b74;
    font-weight: 600;
    font-size: 0.8vw;
    padding: 14px;
    display: flex;
}

.dashboard__header-banner-en-proceso i {
    color: #acacac;
    margin-right: 1vw;
}

progress[value] {
    width: 50%;
    padding: 1.5vh 0px;
}

.progress-labels {
    width: 50%;
    display: flex;
    justify-content: space-between;
    color: #acacac;
}

.progress-labels i {
    color: #194b74;
}

.dashboard-en-proceso__content {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    text-align: center;
}

.dashboard-en-proceso__content img {
    width: 10vw;
    margin: 5vh 0px;
}

.dashboard-user__header {
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
}

.dashboard-usuario-1__header-banner-en-proceso {
    background: #194b74;
    border-radius: 8px;
    width: 50%;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.8vw;
    padding: 14px;
    display: flex;
}

.dashboard-usuario-1__header-banner-en-proceso i {
    color: #fbc02a;
    margin-right: 1vw;
}

.dashboard-usuario-1__header-banner-en-proceso span {
    color: #fbc02a;
    margin-right: 1vw;
}

.dashboard-usuario-1__header-banner-en-proceso label {
    color: #acacac;
    margin-right: 1vw;
}

.dashboard-usuario-1__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
}

.dashboard-usuario-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    width: 50%;
    margin-top: 9vw;
    padding: 2vh 0px;
}

.dashboard-usuario-1__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 70%;
    margin-top: 5vw;
    padding: 2vh 0px;
}

.dashboard-usuario-1__bank {
    display: flex;
    flex-direction: column;
    text-align: "left";
    box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 70%;
    margin-top: 5vw;
    padding: 2vh 0px;
}

.dashboard-usuario-1__card h2 {
    margin: 1vh 0px;
}

.dashboard-usuario-1__input-group {
    margin: 3vh 0px;
    display: flex;
}

.dashboard-usuario-1__input-group div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboard-usuario-1__input-group span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 1vw;
}

.dashboard-usuario-1__small-cards-container {
    margin: 5vh 0px;
    display: flex;
    justify-content: space-between;
    width: auto;
}
.dashboard-banck__cards-container{
    display: flex;
    justify-content: space-between;
    width: auto;
}
.dashboard-banck__cards{
    margin: 20px;
}

.dashboard-banck__small-cards-container {
    margin: 10vh 0px;
    display: flex;
    text-align: left;
    justify-content: space-between;
    width: auto;
}

.dashboard-banck__small-cards-container a {
    text-decoration: none;
    color: #194b74;
}

.dashboard-banck__small-cards-container div {
    background-color: #f6f6f6;
    display: flex;
    height: 188px !important;
    width: 205px !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 2vw;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.dashboard-banck__small-cards-container div img {
    height: 85px;
    width: 85px;
    margin: 1vw 0px;
}

.dashboard-banck__small-cards-container input {
    background: #f6f6f6;
    padding: 12px;
    flex: 0 1 10%;
    display: flex;
    width: 15vw;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9vw;
    border-radius: 2px;
    border: 1px solid rgb(37, 37, 37);
}

.dashboard-usuario-1__small-cards-container div {
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 1vw;
    padding: 2vh 1vw;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}


/* Alf */

.dashboard-usuario-1__small-cards-container div h3 {
    margin: 1vh 0px;
    font-weight: 800;
    font-size: 1.5vw;
}

.dashboard-usuario-1__small-cards-container div span {
    color: #567384;
    font-weight: 500;
    font-size: 0.9vw;
}

.tabla-pagos {
    margin: 5vh 0px;
    width: 80%;
    text-align: center;
}

.tabla-pagos h4 {
    font-weight: 900;
    font-size: 1.5vw;
}

.success-text {
    color: #2e915c;
}

.error-text {
    color: #b44d2c;
}

.listo {
    background: #2e915c;
    border-radius: 20px;
    opacity: 1;
    font-size: 0.8vw;
    padding: 5px 10px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.listo img {
    width: 1vw;
}


/* table */

table.blueTable {
    border: 8px solid #f6f6f6;
    background-color: #eeeeee;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

table.blueTable td,
table.blueTable th {
    border: 1px solid #aaaaaa;
    padding: 3px 2px;
}

table.blueTable tbody td {
    font-size: 13px;
    color: #acacac;
}

table.blueTable tr:nth-child(even) {
    background: #f6f6f6;
}

table.blueTable thead {
    background: #f6f6f6;
    background: -moz-linear-gradient(top, #f8f8f8 0%, #f7f7f7 66%, #f6f6f6 100%);
    background: -webkit-linear-gradient( top, #f8f8f8 0%, #f7f7f7 66%, #f6f6f6 100%);
    background: linear-gradient(to bottom, #f8f8f8 0%, #f7f7f7 66%, #f6f6f6 100%);
    border-bottom: 2px solid #acacac;
}

table.blueTable thead th {
    font-size: 15px;
    font-weight: bold;
    color: #acacac;
    border-left: 2px solid #f6f6f6;
}

table.blueTable thead th:first-child {
    border-left: none;
}

table.blueTable tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #f6f6f6;
    background: #f6f6f6;
    background: -moz-linear-gradient(top, #f8f8f8 0%, #f7f7f7 66%, #f6f6f6 100%);
    background: -webkit-linear-gradient( top, #f8f8f8 0%, #f7f7f7 66%, #f6f6f6 100%);
    background: linear-gradient(to bottom, #f8f8f8 0%, #f7f7f7 66%, #f6f6f6 100%);
    border-top: 2px solid #acacac;
}

table.blueTable tfoot td {
    font-size: 14px;
}

table.blueTable tfoot .links {
    text-align: right;
}

table.blueTable tfoot .links a {
    display: inline-block;
    background: #f6f6f6;
    color: #acacac;
    padding: 2px 8px;
    border-radius: 5px;
}


/* estado de cuenta */

.dashboard-estado-cuenta__container {
    color: #194b74;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 50px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.accordion-container {
    color: #194b74;
    padding: 2vw;
}

.accordion-container button {
    margin: 0vw 2vw 0vw 0vw;
    font-weight: 500;
}

.accordion-container button i {
    margin: 0px 0.2vw;
}

.accordion {
    background-color: white;
    cursor: pointer;
    border-bottom: 1px solid #acacac;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 1.3vw;
    transition: 0.4s;
}

.accordion div {
    display: flex;
    align-items: center;
}

.accordion .active,
.accordion:hover {
    background-color: #ccc;
}

.accordion div:after {
    content: "\002B";
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.accordion.active div:after {
    content: "\2212";
}

.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.center-div {
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* SOLICITUD B */

.solicitud-b__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.solicitud-b__header {
    width: 50%;
    text-align: center;
    margin: 70px 0px;
}

.solicitud-b__header h1 {
    margin: 0;
}

.solicitud-b__header span {
    font-weight: 700 !important;
}

.solicitud-b__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solicitud-b__inciso {
    display: flex;
}

.inciso-header-text {
    width: 100%;
    justify-content: space-between;
}

.solicitud-b__inciso h4 {
    margin: 0;
    font-size: 18px;
}

.solicitud-b__inciso {
    display: flex;
    flex-direction: column;
    width: 90%;
    
   
}

.solicitud-b__inciso>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.solicitud-b__inciso>div>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.solicitud-b__inciso>div>div>div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.solicitud-b__inciso img {
    width: 10%;
}

.solicitud-b__inciso span {
    width: 80%;
}

.drag_and_drop {
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 200px;
    align-items: center;
    justify-content: center;
    background: #f6f6f6;
    box-shadow: 0px 2.91473px 10.9302px rgba(0, 0, 0, 0.1);
    border-radius: 2.18605px;
    margin: 18px 16px 9px;
}

.drag_and_drop i {
    font-size: 3vw;
    margin-bottom: 20px;
}

.solicitud-b__inciso-content {
    padding-top: 50px;
}

.solicitud-b__inciso-content input {
    margin: 0px 50px 10px 0px;
}

.solicitud-b__inciso-input-group {
    height: auto;
    width: 200px;
    margin: 100px 50px 10px 0px;
}

.ahref-cp {
    position: absolute;
    margin-top: 70px;
}

.solicitud-b__bottom {
    margin-top: 50px;
}

.solicitud-b__bottom2 {
    margin-top: 20px;
}

.solicitud-b__bottom h4 {
    margin: 0px;
}

.collapsed {
    display: none !important;
}

.incisio-header div:first-child {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-self: start;
}

.incisio-header {
    display: flex;
    justify-content: space-between !important;
}

.incisio-header img {
    width: 5%;
}

.toggle-collapsed {
    width: 10% !important;
    margin-left: 50px;
}

.toggle-collapsed:hover {
    cursor: pointer;
    user-select: none;
}

.fa-file-upload img {
    content: "\f574";
    width: 30%;
    height: auto;
}

.solicitud-margin {
    margin: 100px 50px 10px 0px;
    position: relative;
}

.dashboard__card {
    transform: translateY(100px);
}

.solicitud-credito {
    color: #194b74;
    padding: 20px;
}

.solicitud-credito h2 {
    margin-top: 50px;
    font-size: 22px;
    font-weight: 800;
    text-align: center;
}

.solicitud-credito__header h1 {
    font-weight: 900;
    font-size: 32px;
}

.solicitud-credito__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solicitud-credito__content h4 {
    font-weight: 900;
}

.solicitud-credito__item {
    display: flex;
}

.solicitud-credito__credito {
    margin-top: 50px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.solicitud-credito__creditoNew {
    margin-top: 50px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.solicitud-credito__creditoNEW h4 {
    font-size: 20px;
    margin: 40px 0;
}

.solicitud-credito__credito h4 {
    font-size: 20px;
    margin: 40px 0;
}

.solicitud-credito__amount {
    width: 50%;
}

.solicitud-credito__termn {
    text-align: center;
    width: 50%;
}

.solicitud-credito__input-group {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.solicitud-credito__recurrence {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.solicitud-credito__result {
    justify-content: center;
    justify-self: center;
    margin-left: auto;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-right: auto;
    padding: 20px;
    width: 80%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.solicitud-credito__contrato {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.solicitud-credito__contrato h2 {
    text-align: center;
    width: 100%;
}

.solicitud-credito__buttons {
    display: flex;
    justify-content: center;
}

.solicitud-credito__buttons button {
    margin: 50px 5px;
}

.solicitud-credito__buttons .descargar-contrato {
    border: 1px solid #194b74;
    border-radius: 4px;
    padding: 14px 10px;
    background: #fff;
}

.custom-slider {
    padding: 0px 50px;
    text-align: center;
    width: 100%;
}

.custom-slider__range-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 900;
    font-size: 18px;
    color: #194b74;
}

.MuiSlider-valueLabel:after {
    content: " ";
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.img-back-credito {
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 300px;
}

.landing-section__second-column-texto .custom-slider {
    width: 80%;
}

.animation-container {
    width: 100px;
    margin-top: 100px;
}

nav .mobile-btn {
    display: none;
}

.title-mobile-landing {
    display: none;
}

.rec-arrow {
    background: none;
    border: none;
    color: #fff;
}

.lgksPe:hover:enabled,
.lgksPe:focus:enabled {
    background: none;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

footer p {
    opacity: 0.6;
}

.add-colonia {
    margin-top: 20px;
}

.landing-form {
    display: flex;
    flex-wrap: wrap;
}

.landing-form>div {
    width: 40%;
    margin: 10px 0;
}

.form-container button {
    margin-top: 20px;
}

.solicitud-1__form-solicitud .landing-form>div {
    margin-right: 30px;
}

.solicitud-1__form-solicitud .landing-form label {
    font-size: 14px;
    margin-bottom: 10px;
}


/*************************** mobile *************************/

@media (max-width: 1200px) {
    .password-input {
        width: 100% !important;
    }
    footer p {
        font-size: 10px;
    }
    .form-container {
        text-align: center;
        padding: 20px 0;
    }
    .landing-section__container .landing-section__first-column {
        padding: 0;
    }
    form.landing-form {
        padding: 0;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    form.landing-form input {
        width: 100% !important;
    }
    form.landing-form div {
        width: 90%;
        display: flex;
        overflow-x: hidden;
        flex-direction: column;
        align-items: flex-start;
    }
    h2 {
        font-size: 16px;
    }
    .MuiAccordionDetails-root {
        overflow-x: scroll;
    }
    span.estado-cuenta {
        text-align: center;
        margin-top: 10px;
    }
    .dashboard-usuario-1__small-cards {
        width: 100% !important;
        margin: 10px 0 !important;
    }
    .dashboard-usuario-1__small-cards-container {
        flex-direction: column;
    }
    .dashboard__header-banner-en-proceso {
        width: 90%;
        margin-top: 10px;
        font-size: 12px;
    }
    .dashboard-en-proceso__content h2 {
        font-size: 16px;
        margin-top: 20px;
    }
    .dashboard__header {
        flex-direction: column;
    }
    .dashboard-en-proceso__content {
        width: 100%;
        margin-top: 0;
    }
    .tabla-pagos h4 {
        font-size: 16px;
    }
    .tabla-pagos {
        overflow-x: scroll;
    }
    .dashboard-usuario-1__header-banner-en-proceso {
        width: 90%;
        margin-top: 10px;
        font-size: 12px;
        flex-direction: column-reverse;
    }
    .dashboard-usuario-1__card {
        width: 80%;
        padding: 20px;
    }
    .dashboard-usuario-1__small-cards-container {
        margin: 20px 0;
        width: 90%;
    }
    .dashboard-usuario-1__small-cards-container div h3 {
        font-size: 14px;
    }
    .dashboard-usuario-1__small-cards {
        width: 25%;
    }
    .dashboard-usuario-1__small-cards span {
        font-size: 10px !important;
        text-align: center;
    }
    .dashboard-usuario-1__card h2 {
        font-size: 14px;
        text-align: center;
    }
    .dashboard-usuario-1__header-banner-en-proceso>div {
        margin: 5px 0;
    }
    .dashboard__container h1 {
        font-size: 14px;
    }
    .dashboard-user__header {
        flex-direction: column;
    }
    .name-date span {
        font-size: 12px;
    }
    .solicitud-credito__amount,
    .solicitud-credito__termn {
        width: 100%;
    }
    .solicitud-credito__credito {
        width: 100%;
        flex-direction: column;
        margin-top: 10px;
    }
    .solicitud-credito__creditoNEW {
        width: 100%;
        flex-direction: column;
        margin-top: 10px;
    }
    .login__input-group label {
        font-size: 12px;
    }
    .login__content {
        align-items: flex-start;
        padding-top: 100px;
    }
    .login__input-group input {
        width: 95%;
    }
    .password-input img {
        right: 10px;
    }
    .login__input-group {
        width: 100%;
        margin: 10px 0;
    }
    .login__content h1 {
        font-size: 24px;
    }
    .login__content h3 {
        font-size: 16px;
    }
    .login__card {
        width: 80%;
        z-index: 1;
        padding: 20px;
    }
    .login__family,
    .login__blue-vector {
        width: 200px;
    }
    .solicitud-6__logo {
        width: 200px;
    }
    .solicitud-6__checkmark {
        display: none;
    }
    .solicitud-6__form-solicitud a {
        margin: 20px 0;
    }
    .animation-container {
        width: 50px;
    }
    .solicitud-5__tabs a span {
        font-size: 0;
    }
    .solicitud-5__tabs a span img {
        width: 10px;
    }
    .solicitud-5__tabs a {
        font-size: 12px;
        width: 50%;
    }
    .solicitud-2__content img {
        width: 200px;
    }
    .solicitud-2__content,
    .solicitud-3__content,
    .solicitud-5__form-solicitud,
    .solicitud-6__form-solicitud {
        margin: 0;
        width: 80%;
        padding: 30px;
    }
    .solicitud-1__input-group div input {
        width: 90%;
    }
    .solicitud-1__form-solicitud .solicitud-1__input-group {
        padding: 0;
    }
    .solicitud-1__form-solicitud .solicitud-1__input-group .password-input a {
        display: none;
    }
    .solicitud-1__form-solicitud .solicitud-1__input-group span {
        display: none;
    }
    .solicitud-1__input-group {
        flex-direction: column;
    }
    .solicitud-1__tabs a {
        font-size: 12px;
    }
    .solicitud-1__tabs {
        margin-top: 10px;
    }
    .solicitud-1__form-solicitud {
        width: 100%;
        padding: 20px;
        text-align: center;
    }
    .solicitud-1__form-solicitud {
        margin: 0;
    }
    .add-colonia {
        font-size: 12px !important;
        margin-top: 20px;
    }
    .solicitud-b__inciso>div>div {
        flex-direction: column;
    }
    .solicitud-b__inciso-content input {
        width: 90%;
    }
    .solicitud-b__inciso-content .input-select {
        width: 95%;
        font-size: 16px;
    }
    .solicitud-b__inciso-content {
        flex-direction: column;
    }
    .solicitud-b__inciso>div>div>div {
        justify-content: center;
    }
    .solicitud-b__inciso>div>div>div span {
        text-align: center;
        margin-top: 10px;
    }
    .files-accordeon>div {
        align-items: center;
        margin: 10px 0;
    }
    .solicitud-b__inciso>div {
        justify-content: center;
    }
    .decoration-image {
        display: none;
    }
    .files-accordeon {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .files-accordeon .drag_and_drop {
        margin: 0;
    }
    .solicitud-b__container .solicitud-b__header {
        width: 80%;
        margin: 30px 0;
    }
    .solicitud-b__container .solicitud-b__header h1 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .incisio-header img {
        display: none;
    }
    .solicitud-b__container {
        padding: 0 30px;
    }
    .toggle-collapsed {
        margin: 10px;
    }
    .solicitud-b__inciso h4 {
        font-size: 16px;
    }
    .termsConditions h1 {
        text-align: center;
    }
    .faq-content {
        padding: 0px 30px !important;
    }
    .faq-content .questions-container {
        flex-direction: column;
        align-items: center;
    }
    .faq-content .questions-container .pregunta {
        width: 100%;
        margin: 20px 0;
    }
    .pasos-a-seguir .solicitud-1__form-solicitud {
        width: 80%;
    }
    .pasos-a-seguir .pasos-container {
        flex-direction: column;
    }
    .pasos-a-seguir .pasos-container .paso {
        height: auto;
        width: 100%;
    }
    .pasos-container i {
        transform: rotate(90deg);
    }
    .pasos-a-seguir .solicitud-1__form-solicitud {
        margin: 20px 30px !important;
    }
    .termsConditions p {
        width: 80% !important;
        text-align: center;
    }
    .landing-section__container div {
        width: 100%;
    }
    .landing-section__second-column-texto {
        padding: 0px;
    }
    html,
    body {
        overflow-x: hidden;
    }
    .landing-first-section-btn {
        margin-top: 30px !important;
    }
    .mobile-items {
        display: none;
    }
    .mobile-items-container {
        display: none;
    }
    nav {
        position: relative;
        z-index: 10 !important;
        padding: 5px 0;
    }
    nav .logo {
        display: inline-block;
        vertical-align: top;
        height: 45px;
        margin-left: 10px;
    }
    nav .mobile-btn {
        display: block !important;
        position: absolute;
        right: 25px;
    }
    .navigation-bar .right {
        display: none;
    }
    nav .left-cont {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 20px;
    }
    nav .right-cont {
        display: none;
    }
    nav .right-cont a img {
        margin: 2px;
    }
    nav .right-cont a {
        justify-content: center;
        align-items: center;
        display: flex;
    }
    nav i {
        font-size: 28px;
        color: #194b74;
    }
    .separator {
        border-left: none;
    }
    .navigation-bar a {
        transition: 0.3s ease-in;
        font-size: 12px;
        line-height: normal;
        font-weight: bold;
        margin: 0;
    }
    .MuiDrawer-paper a {
        text-decoration: none;
        color: #194b74;
        font-size: 16px;
        font-weight: 600;
    }
    .MuiDrawer-paper img {
        height: 60px;
    }
    .landing-section__container {
        flex-direction: column-reverse;
        position: relative;
    }
    .landing-section__second-column-texto h2 {
        font-size: 24px;
        z-index: 1;
    }
    .background-triangle {
        z-index: 10;
        position: absolute;
        height: 200px;
        opacity: 0.8;
        top: -70px;
        left: -15px;
    }
    .custom-slider {
        margin-bottom: 30px;
    }
    .yellow {
        font-size: initial;
    }
    .title-mobile-landing {
        display: flex;
        justify-content: center;
        padding-left: 20px;
    }
    .title-mobile-landing h1 {
        font-size: 24px;
        padding-left: 20px;
        text-align: justify;
        z-index: 1;
    }
    .title-mobile-landing h1 strong,
    .title-mobile-landing h1 span {
        font-size: 36px;
    }
    .title-mobile-landing h1 strong {
        font-weight: 900;
    }
    .landing-section__first-column--info {
        display: none;
    }
    .landing-section__first-column {
        padding: 20px;
        width: 80%;
        flex-direction: column;
        display: flex;
        align-self: center;
    }
    .landing-section__first-column .solicitud-1__input-group {
        flex-direction: column;
        display: flex;
    }
    .solicitud-1__input-group {
        width: 100%;
        padding: 20px;
    }
    .landing-section__container h3 {
        font-size: 26px;
        text-align: center;
    }
    .solicitud-1__input-group {
        align-items: center;
    }
    .solicitud-1__input-group label {
        text-align: center;
    }
    .solicitud-1__input-group div {
        width: 100%;
    }
    .landing-section__first-column input {
        width: 85%;
        margin: 0;
    }
    .landing-section__first-column form {
        text-align: center;
    }
    .landing-section__first-column button {
        font-size: initial;
    }
    .banner-section__container {
        flex-direction: column;
    }
    .reviews__container h2 {
        display: none;
        font-size: initial;
    }
    .reviews__container {
        padding: 50px 20px;
    }
    .reviews__cards-container .card {
        width: 100%;
        padding: 20px;
    }
    .como-funciona__container {
        padding: 40px 30px;
    }
    .como-funciona__container h2 {
        font-size: 22px;
        text-align: center;
    }
    .como-funciona__container span {
        text-align: center;
    }
    .como-funciona__info {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
    }
    .como-funciona__info div {
        margin: 20px 0;
        width: 50% !important;
    }
    .como-funciona__info div h4 {
        font-size: initial;
    }
    .como-funciona__info div span {
        width: 100%;
    }
    .como-funciona>img {
        max-width: 100vw;
        width: 100%;
    }
    .banner-section__container {
        align-items: center;
        height: auto;
    }
    .banner-section__container div:first-child {
        width: 100%;
    }
    .banner-section__container div h3 {
        font-size: 24px;
        width: 80%;
        padding: 30px 0;
        font-weight: 800;
    }
    .tienes-dudas-section__container {
        padding: 20px;
    }
    .tienes-dudas-section__container h2 {
        font-size: 26px;
    }
    .tienes-dudas-section__container>span {
        font-size: initial;
        text-align: center;
    }
    .tienes-dudas-section__button-group {
        margin: 10px 0;
        align-self: flex-start;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .tienes-dudas-section__button-group a {
        font-size: 12px !important;
        margin: 10px 0;
    }
    .footer__first-container {
        flex-direction: column;
    }
    .footer__first-container div {
        flex-direction: column;
    }
    .dash-nav-items-mobile-hide {
        display: none !important;
    }
    footer {
        padding: 20px;
    }
    .footer__first-container .logo {
        height: 100px;
        margin: 0 0 20px 0;
    }
    .footer__first-container div {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .footer__social-icons {
        margin: 10px 0;
        justify-content: flex-start !important;
        width: 100%;
    }
    .footer__social-icons img {
        height: 40px;
        margin-right: 10px;
    }
    .footer__first-container a {
        margin: 5px 0;
    }
    footer p {
        opacity: 0.6;
    }
}

.navigation-bar {
    padding: 5px;
}

@media (max-width: 1280px) {
    .questions-container {
        justify-content: center;
    }
    .solicitud-1__form-solicitud {
        width: 50%;
    }
    .como-funciona__info div {
        width: 30%;
    }
    .landing-section__container li {
        font-size: 12px;
    }
    .solicitud-1__input-group div label {
        font-size: 12px;
    }
    .landing-first-section-btn {
        margin-bottom: 0;
    }
    .landing-section__first-column {
        padding: 30px 10px 30px 20px;
    }
    .landing-section__first-column input {
        margin-right: 0;
    }
    input,
    .input {
        width: 150px;
        height: 25px;
        background: #f6f6f6;
        border-radius: 2px;
        border: 0px;
        padding: 8px;
    }
    .landing-section__container div {
        margin-top: 0px;
    }
    .solicitud-1__input-group div label {
        margin: 10px 0px !important;
    }
    .solicitud-1__input-group div {
        margin: 0px !important;
    }
    .solicitud-1__input-group div input {
        margin-bottom: 30px !important;
    }
    .landing-section__container h3 {
        margin: 10px 0;
    }
    button {
        font-size: 12px !important;
    }
    .navigation-bar a {
        font-size: 10px;
    }
    .logo {
        height: 40px;
        padding: 5px 0;
    }
    .tienes-dudas-section__container {
        padding: 30px;
    }
    .tienes-dudas-section__container>span {
        font-size: 16px;
        margin: 10px 30px;
        text-align: center;
    }
    .banner-section__container {
        overflow-x: scroll;
    }
    .tienes-dudas-section__button-group {
        margin: 40px 0;
        flex-wrap: wrap;
        display: flex;
    }
    .tienes-dudas-section__button-group a {
        font-size: 10px;
        margin: 10px 5px;
    }
}

.como-funciona__container {
    padding: 20px 0;
}

.descripcion_carta {
    margin: 40px 0;
}

.nombre_carta {
    margin: 30px 5px 5px 0;
    font-size: 22px;
    font-weight: 600;
}

@media (max-width: 1200px) {
    .nombre_carta {
        text-align: center;
    }
}

.pasos-a-seguir {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.pasos-a-seguir h1 {
    color: #194b74;
    font-weight: 800;
    font-family: "Raleway";
    height: 100%;
    text-align: center;
}

.pasos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.pasos-container .paso {
    width: 30%;
    margin: 20px 10px;
    text-align: center;
    height: 200px;
    justify-content: space-between;
    transition: 0.5s ease;
}

.pasos-container i {
    color: #194b74;
    font-size: 28px;
    margin: 0 20px;
}

.pasos-container h4 {
    color: #194b74;
    font-weight: 700;
    font-size: 24px;
}

.pasos-container span {
    color: #194b74;
    font-weight: 500;
}

.pasos-a-seguir .solicitud-1__form-solicitud {
    margin: 150px 0px;
}

.pasos-a-seguir {
    position: relative;
}

.pasos-a-seguir .back-image {
    position: absolute;
    left: -100px;
    height: 50vw;
    opacity: 0.4;
    top: 0;
}

.pasos-container .paso:hover {
    cursor: pointer;
    transition: 0.5s ease;
    transform: translateY(-10px);
}

.footer_100vh {
    height: 100vh;
}

.faq {
    /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; */
}

.faq-content {
    text-align: center;
    color: #194b74;
    padding: 100px 40px;
}

.pregunta {
    width: 30%;
    margin: 30px 20px;
}

.pregunta .stilojm2 {
    font-size: 18px !important;
    text-align: initial;
    font-weight: 700;
    color: #194b74;
}

.pregunta .MuiTypography-body1 {
    text-align: initial;
    color: #194b74;
}

.questions-container {
    display: flex;
    flex-wrap: wrap;
}

.cultura-financiera {
    color: #194b74;
}

.cultura-financiera h1 {
    color: #194b74;
    font-weight: 800;
}

.cultura-financiera h2 {
    color: #f0ac00;
    font-weight: 700;
    font-size: 24px;
}

.cultura-financiera p,
.cultura-financiera li {
    color: #194b74;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
}

.cultura-financiera .back-image {
    position: absolute;
    z-index: -1;
    opacity: 0.3;
}

.cultura-financiera {
    position: relative;
}

.customAccordeon .MuiAccordionSummary-content {
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0 10px;
}

.customAccordeon .MuiAccordionSummary-content button.blue {
    margin: 0;
}

.termsConditions,
.avisoPrivacidad {
    padding: 30px 20px;
    display: flex;
    text-align: justify;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.avisoContent{
  padding-right: 5rem;
  padding-left: 5rem;
  text-align: justify;
}

.aceptarPAVISO{
    width: 100%;
  }

.avisopng{
    display: flex;
    justify-content: center;
    align-items: center;
}

.termsConditions h1,
.avisoPrivacidad h1 {
    color: #2d73ac;
    align-self: center !important;
}

 .termsConditions ol {
    margin:0 0 1.5em;
    padding:0;
    counter-reset:item;
  }
   
  .termsConditions ol>li {
    margin:0;
    padding:0 0 0 2em;
    text-indent:-2em;
    list-style-type:none;
    counter-increment:item;
  }
   
  .termsConditions ol>li:before {
    display:inline-block;
    width:1.5em;
    padding-right:0.5em;
    font-weight:bold;
    text-align:right;
    content:counter(item) ".";
  }

  .react-pdf__Page__canvas {
    min-height: 100vh ! important;
    max-width: 100vw! important;
  }

.termsConditions p {
    width: 70%;
    text-align: justify;
}

.avisoPrivacidad {
    text-align: initial;
    align-items: flex-start !important;
}

.InpWhite {
    background-color: white;
}

.form-container1 {
    display: flex;
    justify-content: center;
    align-content: center;
}


/* Credit Type */

.solicitud-2__form {
    margin-top: 1rem;
}

.code-h2{
    margin-bottom: 2rem;
}

.calleInput{
    text-transform: uppercase;
}

.credit-type-header{
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-center{
    text-align: center;
}

.pdfTerminos{
    margin: auto;
    display: block;
    width: 850px;
    height: 100vh;
}

/* Credit Progress*/
.text-area_comentarios{
    padding: 10px;
    max-width: 100%;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px #999;
    box-sizing: border-box;
  	resize: none;
    margin: 5px;
}

.dashboard-banck__small-cards-container :hover {
    transition: 0.3s ease;
    transform: scale(1.1);
    box-shadow: 10px;
}
