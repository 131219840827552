.btnSubmitWrapReferences {
    display: flex !important;
    justify-content: center !important;
    width: 100%;
}

.inputErr {
    border: 1px solid rgb(204, 1, 1);
}

.segunda-referencia{
    text-align: center;
}