.address{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    &__columns {
      width: 200px;
      margin: 0 20px;
  
      &-wrapInput {
        min-height: 70px;
        margin: 20px 0;
        width: 100%;
  
        label {
          font-size: 14px;
        }
  
        select,
        input {
          font-size: 14px;
          padding: 12px;
          width: 100%;
        }
  
        .select-peps {
          border: none;
          background: #f6f6f6;
          border-radius: 2px;
          width: 100%;
        }
      }
  
      .doubleInput {
          
          .inputsAddresWrap {
            width: 100%;
            display: flex;

            input {
                width: 80px;

                &:first-child {
                    margin-right: 20px;
                }
            }
        }
      }
    }
  }  

.inputErr {
    border: 1px solid rgb(204, 1, 1);
}

.btnSubmitWrapAddres {
    display: flex !important;
    justify-content: center !important;
    width: 100%;
}


.informacionC {
  max-width: 80rem;
  margin: 2rem !important;
}

.informacionC div{
  margin: .5rem !important;
}

.informacionC div label {
  color: #194b74;
}

.informacionC select,
input {
  background: #f6f6f6;
  border-radius: 2px;
  padding: 12px;
  flex: 0 1 10%;
  display: flex;
  width: 290px;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  outline-width: 0;
}

.informacionC input {
  background: #f6f6f6;
  border-radius: 2px;
  padding: 12px;
  flex: 0 1 10%;
  display: flex;
  width: 270px;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  outline-width: 0;
}

.ButtonV {
  align-items: center;
  display: flex;
  justify-content: center;
}

.Button-cpr {
  display: flex;
  justify-content: center;
  align-items: center;
  }

.Button-cpr button{
  margin-left: 2rem;
  margin-right:2rem ;
}


.hg1 {
  border-bottom: #FBC02A;
}

.invalidV {
  border: 1px solid red;
}

.invalid {
  color: red;
}

.navbar2 .hg11,
.hg31,
.hg41,
.hg51 {
  margin: 2rem 1rem;
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  color: #194b74;
  font-size: .8rem;
}

.navbar2 .hg21 {
  margin: 2rem 1rem;
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  color: #194b74;
  font-size: bold;
}

div.domicilioTime {
  display: flex !important; 
  align-content: center !important; 
  flex-direction: row !important;
  margin-left: 1rem !important;
  column-gap: 10px;
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.domicilioTimeI{
  width: 6rem !important;
  margin-left: 0;
}

.domicilioTimeE {
  display: flex !important; 
  align-content: center !important; 
  flex-direction: row !important;


}

.domicilioTimeE div{
  margin-top: -.2rem !important;
  margin-left: 5.5rem !important;
}

.DomicilioL{
  margin-left: 5rem !important;
}







.DTime {
  display: flex !important; 
  align-content: center !important; 
  flex-direction: row !important;
  margin-left: 1rem !important;
}

.DTimeI{
  width: 6rem !important;
 margin-right: 2rem !important;
 margin-left: -.5rem;
}

.DTimeE {
  display: flex !important; 
  align-content: center !important; 
  flex-direction: row !important;


}

.DTimeE div{
  margin-top: -.5rem !important;
 margin-left: 2rem !important;
 margin-right: 2rem !important;
}


