.Especificar input{

    width: 600px;
    margin-top: 1.3rem;
}

@media only screen and (max-width: 700px) {
    .Especificar input{
        width: 300px;
    }
  }

.informacionC{
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar3  .hg12, .hg22 , .hg42,.hg52{
    margin: 2rem 1rem;
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    color: #194b74;
    font-size: .8rem;
  }
  
  .navbar3 .hg32{
    margin: 2rem 1rem;
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    color: #194b74;
    font-size: bold;
  }

  .navbar4  .hg13, .hg23 , .hg33,.hg53{
    margin: 2rem 1rem;
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    color: #194b74;
    font-size: .8rem;
  }
  
  .navbar4 .hg43{
    margin: 2rem 1rem;
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    color: #194b74;
    font-size: bold;
  }

  .Button-cpr {
    display: flex;
    justify-content: center;
    align-items: center;
    }

.Button-cpr button{
    margin-left: 2rem;
    margin-right:2rem ;
}