.qpeps{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  &__columns {
    width: 200px;
    margin: 0 20px;

    &-wrapInput {
      min-height: 80px;
      margin: 15px 0;
      width: 100%;

      label {
        font-size: 14px;
      }

      select,
      input {
        font-size: 14px;
        padding: 11px;
        width: 100%;
      }

      .select-peps {
        border: none;
        background: #f6f6f6;
        border-radius: 2px;
        width: 100%;
      }
    }
  }
}

.inputErr {
  border: 1px solid rgb(204, 1, 1);
}

.btnSubmitWrapReferences {
  display: flex !important;
  justify-content: center !important;
  width: 100%;
}
