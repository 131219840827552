.menuPersonalCredit {
    display: flex;
    justify-content: center !important;

    margin-top: -20px;
    margin-bottom: 30px;
    width: 100%;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
            font-size: 12px;
            font-weight: 600;
            margin-top: 15px;
            margin-left: 20px;
            list-style: none;

            &.activeStep {
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
}