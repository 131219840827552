.wrapper-tooltip-pregunta {
    --background: #62ABFF;
    --icon-color: #414856;
    --shape-color-01: #B8CBEE;
    --shape-color-02: #7691E8;
    --shape-color-03: #FDD053;
    --width: 30px;
    --height: 10px;
    --border-radius: var(--height);
    width: var(--width);
    height: var(--height);
    position: relative;
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    .btn {
    width: var(--width);
      height: var(--height);
      position: relative;
      z-index: 3;
      border-radius: var(--border-radius);
      box-shadow: 0 10px 30px rgba(#414856, 0.05);
      display: flex;
      justify-content: center;
      align-items: center; 
      animation: plus-animation-reverse .5s ease-out forwards;
      &::before {
        width: 4px;
        height: 28px;
      }
      &::after {
        width: 28px;
        height: 4px;
      }
    }
    .tooltip {
      width: 90px;
      height: 75px;
      border-radius: 70px;
      position: absolute;
      background: #fff;
      z-index: 2;
      padding: 0 15px;
      box-shadow: 0 10px 30px rgba(#414856, 0.05);
      opacity: 0;
      top: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      transition: opacity .15s ease-in, top .15s ease-in, width .15s ease-in;
      > svg {
        width: 100%;
        height: 26px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        .icon {
          fill: none;
          stroke: var(--icon-color);
          stroke-width: 2px;
          stroke-linecap: round;
          stroke-linejoin: round;
          opacity: .4;
          transition: opacity .3s ease;
        }
        &:hover {
          .icon {
            opacity: 1;
          }
        }
      }
      &::after {
        content: "";
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 3px;
        position: absolute;
        left: 50%;
        margin-left: -10px;
        bottom: -8px;
        transform: rotate(45deg);
        z-index: 0;
      }
    }
    > svg {
      width: 300px;
      height: 300px;
      position: absolute;
      z-index: 1;
      transform: scale(0);
      .shape {
        fill: none;
        stroke: none;
        stroke-width: 3px;
        stroke-linecap: round;
        stroke-linejoin: round;
        transform-origin: 50% 20%;
      }
    }
    input {
      height: 100%;
      width: 100%;
      border-radius: var(--border-radius);
      cursor: pointer;
      position: absolute;
      z-index: 5;
      opacity: 0;
      &:hover {
        ~ svg {
          animation: pang-animation 1.2s ease-out forwards;
          .shape {
            @for $shape from 1 through 9 {
              &:nth-of-type(#{$shape}) {
                transform: translate(random(50) - 25 + px, 30%) rotate(40deg*$shape);
              }
            }
          }
        }
        ~ .btn {
          animation: plus-animation .5s ease-out forwards;
        }
        ~ .tooltip {
          width: 190px;
          height: 70px;
          animation: stretch-animation 1s ease-out forwards .15s;
          top: -90px;
          opacity: 1;
        }
      }
    }
  }
  
  @keyframes pang-animation {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.1);
      opacity: 0;
    }
  }
  @keyframes plus-animation {
    0% {
      transform: rotate(0) scale(1);
    }
    20% {
      transform: rotate(60deg) scale(.93);
    }
    55% {
      transform: rotate(35deg) scale(.97);
    }
    80% {
      transform: rotate(48deg) scale(.94);
    }
    100% {
      transform: rotate(45deg) scale(.95);
    }
  }
  @keyframes plus-animation-reverse {
    0% {
      transform: rotate(45deg) scale(.95);
    }
    20% {
      transform: rotate(-15deg);
    }
    55% {
      transform: rotate(10deg);
    }
    80% {
      transform: rotate(-3deg);
    }
    100% {
      transform: rotate(0) scale(1);
    }
  }
  @keyframes stretch-animation {
    0% {
      transform: scale(1,1)
    }
    10% {
      transform: scale(1.1,.9)
    }
    30% {
      transform: scale(.9,1.1)
    }
    50% {
      transform: scale(1.05,.95)
    }
    100% {
      transform: scale(1,1)
    }
  }
  
  .div-tooltip{
    display: inline-block
  }
  .span-tooltip{
      width: 72% !important;
      float: left;
  }