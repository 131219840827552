.containerVR{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.containerVR img{
    margin: 2rem 2rem;
    height: auto;
    width: 100px;
}

.containerVR h3{
    margin-bottom: 3rem;
    color: #D56947;
}

.containerVR p{
    margin-bottom: 2rem;
    color: #194B74;
}

