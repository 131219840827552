.wrapper {
  margin: 2rem;
}

.uploadBox {
  width: 15rem;
  height: 15rem;
  background-color: #f6f6f6;
  box-shadow: 0px 2.91473px 10.9302px rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 2.18605px;
  justify-content: center;
  align-items: center;
  font-size: 13.12px;
  flex: 0 1 10%;
  flex-direction: column;
  margin-bottom: 1rem;
  cursor: pointer;
}

span {
  text-align: center;
}
a {
  position: relative;
  overflow: hidden;
  color: #2d73ac;
  font-weight: 700;
}
span:hover a {
  color: red;
}
a + input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}


